import React, { useState, useEffect, createRef } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";
import * as LPF from './lp-contact-style';
import * as LP from "./landingpage-style";

// const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID!;
// const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID!;
// const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY!;
const recaptchaSiteKey = '6LdZkXIqAAAAAC5o0_GMWmGL-FTJli562Db81RIc';

const serviceID = "service_bi8o7ga"
const templateID = "template_d29htj8"
// const templateID = "template_vp5ah29"
const publicKey = "1Joh-dNmOIm2qThFK"

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
    gtag_report_conversion: (url?: string) => void;
    grecaptcha: any;
  }
}

export const LpContact: React.FC = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    phone: '',
    email: '',
    companyName: '',
    extraDetails: '',
    referenceCode: '',
  });

  const [acceptConditions, setAcceptConditions] = useState(false);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [sendEmailStatus, setSendEmailStatus] = useState('');
  const recaptchaRef = createRef<ReCAPTCHA>();

  //Define gtag_report_conversion function
  useEffect(() => {
    window.gtag_report_conversion = function (url?: string) {
      const callback = function () {
        if (typeof url !== 'undefined') {
          window.location.href = url;
        }
      };
      if (window.gtag) {
        window.gtag('event', 'conversion_event_submit_lead_form', {
          'event_callback': callback,
          'send_to': 'G-QFXV298GPJ',
        });
      }
      return false;
    };
  }, []);

  const validatePhone = (phone: string) => /^\+?[0-9\s-]{8,15}$/.test(phone);

  const validateEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const validateForm = () => {
    const errors: { [key: string]: string } = {};
    if (!formData.fullName) errors.fullName = '* Ditt namn är obligatoriskt';

    if (!formData.phone) {
      errors.phone = '* Telefonnummer är obligatoriskt';
    } else if (!validatePhone(formData.phone)) {
      errors.phone = 'Telefonnummer måste vara i ett giltigt format';
    }

    if (!formData.email) {
      errors.email = '* E-post är obligatoriskt';
    } else if (!validateEmail(formData.email)) {
      errors.email = 'E-post måste vara i ett giltigt format (t.ex. example@example.com)';
    }
    if (!acceptConditions) errors.acceptConditions = '* Du måste acceptera villkoren för att fortsätta.';
    return errors;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptConditions(e.target.checked);
  };

  const handleSubmit = (token: string | null) => {
    const isDetailsSubmitted = formData.extraDetails || formData.referenceCode;
    if (isDetailsSubmitted) return;

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const combinedFormData = {
      ...formData,
      'g-recaptcha-response': token,
    };

    emailjs.send(serviceID, templateID, combinedFormData, { publicKey }).then(
      () => {
        console.log("Email sent successfully");
        setFormErrors({});
        setSendEmailStatus('OK');
        setFormData({
          fullName: '',
          phone: '',
          email: '',
          companyName: '',
          extraDetails: '',
          referenceCode: '',
        });
        setAcceptConditions(false);
        //Trigger Google conversion tracking after successful form submission
        if (typeof window.gtag_report_conversion === 'function') {
          window.gtag_report_conversion();
        }
        recaptchaRef.current?.reset();
      },
      (error) => {
        console.error("Failed to send email:", error);
        setSendEmailStatus('FAIL');
        recaptchaRef.current?.reset();
      }
    );

  }; // End of handleSubmit function

  const sendEmail = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    recaptchaRef.current?.execute();
  };

  return (
    <LPF.LpContactContainer id="contactform">
    <LP.LpH1>Vi är redo när ni är - <LP.HighlightSpan $color="#E036E0">kontakta</LP.HighlightSpan> oss så sätter vi på
        <LP.HighlightSpan $color="#40BFCC"> kaffet </LP.HighlightSpan> och börjar planera vår resa! </LP.LpH1>


      <LPF.LpForm onSubmit={sendEmail} id="contact-form" noValidate >
        <LPF.LpFormInputContainer>
          <LPF.LpInputContacts
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            aria-label="För & efternamn, obligatoriskt fält"
            autoComplete="name"
            placeholder='För & efternamn*'
          />
          {formErrors.fullName && <LPF.LpErrorMsg>{formErrors.fullName}</LPF.LpErrorMsg>}
        </LPF.LpFormInputContainer>

        <LPF.LpFormInputContainer>
          <LPF.LpInputContacts
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            aria-label="Telefonnummer, obligatoriskt fält"
            autoComplete="tel"
            placeholder='Telefonnummer*'
          />
          {formErrors.phone && <LPF.LpErrorMsg>{formErrors.phone}</LPF.LpErrorMsg>}
        </LPF.LpFormInputContainer>

        <LPF.LpFormInputContainer>
          <LPF.LpInputContacts
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            aria-label="E-post, obligatoriskt fält"
            autoComplete="email"
            placeholder='E-mail*'
          />
          {formErrors.email && <LPF.LpErrorMsg>{formErrors.email}</LPF.LpErrorMsg>}
        </LPF.LpFormInputContainer>

        <LPF.LpFormInputContainer>
          <LPF.LpInputContacts
            type="text"
            id="companyName"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            aria-label="Företagsnamn"
            autoComplete="organization"
            placeholder='Företagsnamn'
          />
        </LPF.LpFormInputContainer>

        <LPF.LpFormInputContainer>
          <LPF.LpDetailsInput
            type="text"
            id="extraDetails"
            name="extraDetails"
            value={formData.extraDetails}
            onChange={handleChange}
            autoComplete="off"
          />
        </LPF.LpFormInputContainer>

        <LPF.LpFormInputContainer>
          <LPF.LpDetailsInput
            type="text"
            id="referenceCode"
            name="referenceCode"
            value={formData.referenceCode}
            onChange={handleChange}
            autoComplete="off"
          />
        </LPF.LpFormInputContainer>



        <LPF.LpCheckboxContainer>
          <LPF.LpCheckboxInput
            type="checkbox"
            id="acceptConditions"
            name="acceptConditions"
            checked={acceptConditions}
            onChange={handleCheckboxChange}
            aria-label="Jag accepterar villkoren"
          />
          <LPF.LpCheckboxLabel htmlFor="acceptConditions">
            Jag accepterar <LPF.VillkorLink to="/privacy">villkoren</LPF.VillkorLink>*
          </LPF.LpCheckboxLabel>
        </LPF.LpCheckboxContainer>

        {formErrors.acceptConditions && <LPF.LpErrorMsg>{formErrors.acceptConditions}</LPF.LpErrorMsg>}
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={recaptchaSiteKey}
          size="invisible"
          onChange={handleSubmit}
        />
        <LP.SendButton type="submit">
          Skicka
        </LP.SendButton>

        {sendEmailStatus === 'OK' && <LPF.SendMsgText>Tack för din förfrågan, vi kontaktar dig inom kort!</LPF.SendMsgText>}
        {sendEmailStatus === 'FAIL' && <LPF.ErrorMsgText>Tyvärr gick inte meddelandet att skicka, testa igen eller kontakta oss på info@andtech.se</LPF.ErrorMsgText>}
      </LPF.LpForm>
    </LPF.LpContactContainer>
  );
};