import React, { useState } from 'react';
import styled from 'styled-components';
import * as LP from './landingpage-style';
import arrowdown from '../../assets/lp-assets/lp-arrow-acc.png';


const FAQContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFF9DE;
  padding: 3rem 0.5rem 5rem 0.5rem;

  @media screen and (min-width: 768px) {
    padding: 3rem 2rem 5rem 2rem;
  }
  @media screen and (min-width: 1024px) { 
    padding: 3rem 5rem 5rem 5rem;
  }
`;

interface FAQItemProps {
  question: string;
  answer: string;
}

const FAQSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 570px;
  margin: 0 auto;
  padding: 18px;
`;

const FAQItemWrapper = styled.div`
  width: 100%;
  margin: 10px 0;
  border: none;
  overflow: hidden;
`;

const FAQQuestion = styled.div<{ $isOpen: boolean }>`
  background-color: ${({ $isOpen }) => ($isOpen ? 'transparent' : 'transparent')};
  color: var(--maindark);
  padding: 12px;
  cursor: pointer;
  font-family: 'safirobold';
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FAQAnswer = styled.div<{ $isOpen: boolean }>`
  background-color: transparent;
  padding: 0 12px;  // Horizontal padding stays the same to avoid shift
  padding-top: ${({ $isOpen }) => ($isOpen ? '12px' : '0')};  // Top padding applied only when open
  max-height: ${({ $isOpen }) => ($isOpen ? '280px' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease, padding-top 0.3s ease;  // Transition only top padding
`;

const ArrowIcon = styled.img<{ $isOpen: boolean }>`
  width: 18px;
  height: 10px;
  transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
`;

export const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => setIsOpen(!isOpen);

  return (
    <FAQItemWrapper>
      <FAQQuestion $isOpen={isOpen} onClick={toggleAccordion}>
        {question}
        <ArrowIcon src={arrowdown} $isOpen={isOpen} alt="Arrow icon" />
      </FAQQuestion>
      <FAQAnswer $isOpen={isOpen}>
        {answer}
      </FAQAnswer>
    </FAQItemWrapper>
  );
};

export const LpFAQ: React.FC = () => {
  const faqItems = [
    {
      question: '+ Vad kostar det?',
      answer: 'Priset på er hemsida beror på flera faktorer, som vilka funktioner ni behöver, hur mycket innehåll ni vill ha hjälp med, och hur komplex den är. Våra priser börjar från 19 900 kr exklusive moms, och vi anpassar våra lösningar utifrån era specifika behov.'
    },
    {
      question: '+ Hur lång tid tar det?',
      answer: 'Räkna med att det tar cirka tre till fyra veckor från första möte till lansering. Behöver du den snabbare än så, berätta det för oss så hittar vi en lösning till er.'
    },
    {
      question: '+ Blir hemsidan mobilanpassad?',
      answer: 'Självklart anpassar vi din hemsida till mobiler och även surfplattor. Vi bygger alltid mobile-first, idag är det väldigt vanligt att vi surfar in på hemsidor från vår mobil och Google prioriterar mobilversionen av din hemsida vid indexering, vilket gör mobilanpassning avgörande för att förbättra din synlighet i sökresultaten.'
    },
    {
      question: '+ Vad innebär SEO-optimering?',
      answer: 'Begreppet "sökmotoroptimering" kanske känns luddigt, så, vad ingår hos oss? Ni får en teknisk optimering från grunden, där vi bygger er hemsida enligt de senaste webstandarderna och bästa praxis från W3C. Detta gör det enkelt för sökmotorer som Google att hitta och indexera ert innehåll, vilket ger en stabil grund för ökad synlighet. Konkurrensen om topplaceringar är hård och kräver mer än bara teknisk korrekthet. Vill du veta hur vi kan hjälpa dig att klättra? Vi berättar gärna hur!'
    },
  ];

  return (
    <FAQContainer>
      <LP.LpH1>FAQ</LP.LpH1>
      <FAQSection>
        {faqItems.map((item, index) => (
          <FAQItem key={index} question={item.question} answer={item.answer} />
        ))}
      </FAQSection>
    </FAQContainer>

  );
};
