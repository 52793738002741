import styled from 'styled-components';
import React from 'react';
import { Link } from 'react-router-dom';


export const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-green);
`;

export const ServiceRowContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1024px) { 
    flex-direction: row;
  }
`;

export const ServicesCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: space-around;
  padding: 30px;
  margin: 20px 5px;
  height: 330px;
  max-width: 450px;
  background-color: #fff;
  box-sizing: border-box;

  @media screen and (min-width: 768px) {
    margin: 50px 20px;
    height: 400px;
    max-width: 600px;
    padding: 50px;
  }

  @media screen and (min-width: 1024px) { 
    font-size: 32px;
    max-width: 500px;
    padding: 50px;
  }
`;


export const ServiceIcons = styled.img`
  width: 60px;
  margin: 0 0 8px 0;

   /* Tablet  */
 @media (min-width: 768px) {
  width: 70px;
  margin: 0 0 15px 0;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    width: 70px;
  }
`;
export const ServiceHead3 = styled.h3`
  font-family: 'safirobold';
  color: var(--maindark);
  font-size: 22px;

  @media screen and (min-width: 768px) {
    font-size: 26px;
  }

  @media screen and (min-width: 1024px) { 
    font-size: 30px;
  }
`;
export const ServieceBread = styled.p`
  color: var(--maindark);
  font-size: 14px;
  margin: 18px 0;
  @media screen and (min-width: 768px) {
    margin: 18px 0 70px 0;
    font-size: 16px;
  }
  @media screen and (min-width: 1024px) { 
    font-size: 18px;
    margin: 18px 0 70px 0;
  }
`;

interface ReadMoreButtonProps {
  to?: string;
}


// Styled component for the button
const ReadMoreLink = styled(Link) <ReadMoreButtonProps>`
  text-align: center;
  border-radius: 50px;
  border: 2px solid var(--maindark);
  background-color: transparent;
  color: var(--maindark);
  text-transform: uppercase;
  text-decoration: none;
  font-family: 'safiromedium';
  font-size: 16px;
  width: 120px;
  padding: 10px 15px;

  &:hover {
    background-color: var(--maindark);
    color: var(--text);
  }

  &:active {
    background-color: var(--bg-pink);
    border: 3px solid var(--bg-pink);
    color: var(--text);
  }

  @media (min-width: 1024px) {
    font-size: 16px;
    margin: 0;
    padding: 11px 22px;
  }
`;

// Component definition
export const ReadMoreButton: React.FC<ReadMoreButtonProps> = ({ to = '/' }) => {
  return (
    <ReadMoreLink to={to}>
      Läs mer
    </ReadMoreLink>
  );
};


// old whatWeDo-style.tsx
export const WhatWeDoContainer = styled.div`
 display: flex;
 flex-direction: row;  
 align-items: center;
 gap: 10px;
 margin-top: 18px;
`;

// For ingress text on whatWeDoIntro.tsx
export const TopHeader = styled.h6`
 color: var(--darktext);
 font-family: 'safiromedium';
 font-weight: 500;
 font-size: 16px;
 text-align: left;
 text-transform: uppercase;
 margin: 18px 0;

 /* Tablet  */
 @media (min-width: 768px) {
  font-size: 26px;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    font-size: 30px;
  }
`;


export const TypeHeader = styled.h6`
 color: var(--darktext);
 font-family: 'safiromedium';
 font-weight: 500;
 font-size: 17px;
 text-align: left;
 line-height: 40px;
 margin: 8px 0;

 /* Tablet  */
 @media (min-width: 768px) {
  font-size: 26px;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    font-size: 30px;
  }
`;

// Illustrated Logos infront of TypeHeader
export const DevLogos = styled.img`
  width: 30px;
  margin: 0;

   /* Tablet  */
 @media (min-width: 768px) {
  width: 35px;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    width: 40px;
  }
`;

export const WhatCoulmnContainer = styled.div`
 display: flex; 
 flex-direction: column;
 align-items: left;
 gap: 10px;
`;



export const ServiceParagraph = styled.p`
 color: var(--darktext);
 font-family: 'inter';
 font-weight: 400;
 font-size: 17px;
 text-align: left;
 line-height: 27px;
 margin: 18px 0 8px 0;

 /* Tablet  */
 @media (min-width: 768px) {
  max-width: 600px;
  font-size: 20px;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    font-size: 22px;
    max-width: 800px;
  }
`;