import React from 'react';
import HamburgerMenu from "./utils/hamburger";
import { Foot } from './pages/footer';
import { LogoMini } from "../components/style/lib";

interface MainLayoutProps {
  children: React.ReactNode;
  isLandingPage?: boolean;
}

export const MainLayout: React.FC<MainLayoutProps> = ({ children, isLandingPage }) => {
  return (
    <>
      {isLandingPage && <div style={{ position: "absolute", left: '26px', top: '26px' }}>{LogoMini(true)}</div>}
      <HamburgerMenu isLandingPage={isLandingPage} />
      <main>{children}</main>
      <Foot />
    </>
  );
};