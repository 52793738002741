// src/components/CookieComponent.tsx
import React, { useEffect, useState } from 'react';
import { useCookie } from 'react-use';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { CookieRoll } from '../gsap/rollCookie';
import kakalight from '../../assets/kaka.svg';


const Banner = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.896);
  color: var(--text);
  padding: 2rem;
  z-index: 1000;

  @media (min-width: 769px) { 
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
     }
`;

const RowContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const CookieP = styled.p`
  font-family: 'Trispace', sans-serif;
  color: var(--text);
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
  text-align: start;
`;

export const ReadMoreLink = styled(Link) <{ to: string }>`
  position: relative;
  font-family: 'Trispace', sans-serif;
  color: var(--mainlight);
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 400;
  text-decoration: none;
  display: inline-block;


  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    height: 3px;
    bottom: -2px;
    left: 0;
    background-color: var(--bg-pink);
    transform-origin: left;
    transition: width 0.5s ease;
  }

  &:hover::before {
    width: 100%;
  }
`;

export const CookieButton = styled.button`
  border-radius: 50px;
  border: 2px solid var(--text);
  background-color: transparent;
  color:  var(--text);
  font-family: 'safiromedium';
  font-size: 16px;
  width: 120px;
  height: 45px;
  margin-top: 40px;
  
  &:hover {
    background-color:  var(--text);
    color: var(--maindark);
  }
`;



export const CookieComponent: React.FC = () => {
  const [cookieConsent, setCookieConsent] = useCookie('cookie-consent');
  const [isBannerVisible, setIsBannerVisible] = useState(!cookieConsent);

  useEffect(() => {
    if (!cookieConsent) {
      setIsBannerVisible(true);
    }
  }, [cookieConsent]);

  const handleAccept = () => {
    setCookieConsent('accepted', { expires: 5 }); // 5 days currently, change if needed
    setIsBannerVisible(false);
  };

  const handleReject = () => {
    setCookieConsent('rejected', { expires: 5 });
    setIsBannerVisible(false);
  };

  if (!isBannerVisible) return null;

  return (
    <Banner>
      <CookieP>
        <CookieRoll cookieSrc={kakalight} backgroundColor='transparent' />
        Vi använder cookies för att webben ska fungera på ett bra sätt för dig och för att analysera trafiken.&nbsp;
        <ReadMoreLink to="/cookies">Läs mer</ReadMoreLink>
      </CookieP>

      <RowContainer>
        <CookieButton onClick={handleAccept}>Acceptera</CookieButton>
        <CookieButton onClick={handleReject}>Avvisa</CookieButton>
      </RowContainer>

    </Banner>
  );
};

