import React from "react";
import {
  Footer,
  FootListContainer,
  FootLogoContainer,
  FootRow,
  NameLink,
  FootLinks,
  FootP
} from "./../style/footer-style";
import { LogoMini } from "../style/lib";

export const Foot: React.FC = () => {
  const currentYear = new Date().getFullYear();


  return (
    <>
      <Footer>
        <FootRow>
          <FootListContainer>
            <NameLink to="/">
              Andtech ab
            </NameLink>
            <FootLinks to="/#contactus">
              Kontakt
            </FootLinks>
            <FootLinks to="/WorkWithUs">
              Jobba med oss
            </FootLinks>
            <FootLinks to="/Privacy">
              Integritetspolicy
            </FootLinks>
          </FootListContainer>

          <FootLogoContainer>
            {LogoMini()}
            <FootP aria-label={`Copyright ${currentYear} AndTech AB`}>
              ©{currentYear}
            </FootP>
          </FootLogoContainer>
        </FootRow>
      </Footer>
    </>

  )
};