import React from "react";
import * as Serv from "../style/whatWeDo-style";
import {
  BaseContainer,
  TextContainer,
  IngressContainer,
  InfoContainer,
  IngressHeader,
  Bread
} from "./../style/lib";
import ux from "../../assets/lp-assets/icons/uxdesign.svg";
import webdev from "../../assets/lp-assets/icons/webbutv.svg";
import hosting from "../../assets/lp-assets/icons/hosting.svg";
import { LpCase } from "../landingpage/lp-case";

export const Webbutveckling: React.FC = () => {
  return (
    <>
      <BaseContainer
        $backgroundColor="var(--bg-green)"
        $padding={['3rem 0 4rem', '5rem 0 5rem', '4rem 0 7rem']
        }
        id="contactus">

        <TextContainer>
          <InfoContainer>
            <IngressContainer>
              <IngressHeader>
                Webbutveckling
              </IngressHeader>
            </IngressContainer>
            <Bread>
              Vi är dedikerade till att ge dig det allra bästa.
              Vi utvecklar alltid från grunden vilket ger dig som kund större valmöjligheter både
              gällande design och funktion. Med vår expertis och flexibilitet skapar vi en webbplats
              som passar just dig.
              <br></br><br></br>
              Letar du efter en pålitlig partner som både kan utveckla och underhålla din hemsida?
              <br></br><br></br>
              Då behöver du inte leta längre. Med de senaste teknikerna skapar vi användarvänliga och
              SEO-optimerade webbplatser—sidor som kommer att imponera på både dig och dina besökare.
              <br></br><br></br>
              Med oss som din digitala partner får du mer än bara en hemsida — du får en skräddarsydd
              helhetslösning som hjälper ditt företag att växa.
            </Bread>


            <Serv.WhatWeDoContainer>
              <Serv.DevLogos src={webdev} alt="Webbutvecling & SEO-optimering" />
              <Serv.TypeHeader>
                Webbutveckling & SEO
              </Serv.TypeHeader>
            </Serv.WhatWeDoContainer>


            <Serv.ServiceParagraph>Inte bara en snygg sajt</Serv.ServiceParagraph>
            <Bread>
              Vi gör mer än att bara skapa vackra webbplatser – Vi höjer även prestanda och användarupplevelse,
              inklusive mobiloptimering.
              <br></br><br></br>
              Genom att analysera era behov sätter vi gemensamt målbilden. Det gör att vi kan skapa en webbplats
              som är både funktionell och tilltalande för användaren, Självklart med en modern och engagerande
              design—vår specialitet.
            </Bread>

            <Serv.ServiceParagraph>Innovation är vårt mellannamn  </Serv.ServiceParagraph>
            <Bread>
              Våra utvecklare är några av de bästa i branschen och alla behärskar hela stacken, från frontend till backend.
              Oavsett om det handlar om att bygga nya funktioner eller integrera befintliga system, ser vi till att din
              webbplats fungerar smidigt och är lätt att underhålla.
              <br></br><br></br>
              Vi förstår att varje kund har sina unika utmaningar – som budget, befintliga system eller integrationer.
              Därför skapar vi innovativa lösningar som fungerar inom dessa ramar, utan att kompromissa på kvaliteten.
            </Bread>

            <Serv.ServiceParagraph>Helhetslösningar som håller</Serv.ServiceParagraph>
            <Bread>
              Vi tar hand om allt—from design och utveckling till hosting och support.
              Med oss får du en lösning som kombinerar attraktiv design med teknisk hållbarhet.
              <br></br><br></br>
              Genom att integrera avancerad SEO från start skapar vi webbplatser som både ser bra ut och rankar högt.
              Vårt erfarna team håller sig uppdaterat med de senaste trenderna, vilket ger din webbplats en
              konkurrensfördel maximal synlighet online.
              <br></br><br></br>
              Med fokus på SEO, säkerhet och snabbhet kan du fokusera på din kärnverksamhet medan vi säkerställer
              att din online-närvaro är i toppklass.
            </Bread>

            <Serv.WhatWeDoContainer>
              <Serv.DevLogos src={ux} alt="Webutveckling" />
              <Serv.TypeHeader>
                UX & Design
              </Serv.TypeHeader>
            </Serv.WhatWeDoContainer>

            <Bread>
              Vi tar ert varumärke till nästa nivå genom att fokusera på användarupplevelse (UX) och design (UI).
              Genom att noggrant kartlägga kundbeteenden på er webbplats omvandlar vi besökare till kunder.
              <br></br><br></br>
              Vi skapar webbupplevelser som inte bara ser bra ut utan också fungerar optimalt. Med fokus på UX designar
              vi engagerande gränssnitt som gör det enkelt för besökare att navigera och interagera.
              <br></br><br></br>
              Denna kombination av estetik med funktion, ger effektiva digitala lösningar som engagerar dina användare
              och även hjälper er att uppnå era mål.
            </Bread>

            <Serv.ServiceParagraph>  Vill ni lyfta er grafiska identitet till nästa nivå?</Serv.ServiceParagraph>

            <Bread>
              Vi hjälper er att skapa unika logotyper och illustrationer som verkligen speglar och förstärker ert
              varumärke.
              <br></br><br></br>
              Tillsammans skapar vi ett visuellt uttryck som sticker ut och engagerar, med ert budskap i fokus
            </Bread>

            <Serv.WhatWeDoContainer>
              <Serv.DevLogos src={hosting} alt="Hosting" />
              <Serv.TypeHeader>
                Hosting & Förvaltning
              </Serv.TypeHeader>
            </Serv.WhatWeDoContainer>

            <Bread>
              Med vår hosting- och förvaltningstjänst får du en problemfri och pålitlig drift av din hemsida.
              Vi tar hand om allt bakom kulisserna, från säkerhet till uppdateringar, så att din webbplats alltid
              är redo för besökare. Du slipper oroa dig för tekniska problem och kan istället se framåt,
              medan vi ser till att din hemsida presterar på topp – dag in och dag ut.
            </Bread>
          </InfoContainer>
        </TextContainer>

      </BaseContainer>
      <BaseContainer
        $backgroundColor="#fff"
        $padding={['0.3rem 1rem', '0.5rem', '1rem 2rem']}>
        <LpCase />
      </BaseContainer>
    </>
  )
};