import styled from "styled-components";
import { Link } from "react-router-dom";

interface LpContainerProps {
  $bgColor?: string;
}

export const LandingPageContainer = styled.div<LpContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.$bgColor || 'var(--mainlight)'};
`;


export const Lpintro = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 95vh;
`;

interface LpH1Props {
  $color?: string;
}

export const LpH1 = styled.h1<LpH1Props>`
  text-align: center;
  font-family: 'safirobold';
  color: ${(props) => props.$color || 'var(--maindark)'};
  font-size: 30px;
  margin: 30px 15px 30px;
  padding: 0;
  max-width: 300px;

  @media screen and (min-width: 768px) {
    font-size: 30px;
    max-width: 600px;
  }
  @media screen and (min-width: 1024px) { 
    font-size: 32px;
    width: 800px;
  }
`;

interface LPSpanProps {
  $color: string;
}

export const HighlightSpan = styled.span<LPSpanProps>`
  color: ${(props) => props.$color};
`;


export const LpBread = styled.p`
  text-align: center;
  color: var(--maindark);
  font-size: 16px;
  margin: 0;
  padding: 0;
  max-width: 305px;

  @media screen and (min-width: 768px) {
    margin: 18px 0;
    font-size: 16px;
    max-width: 600px;
  }
  @media screen and (min-width: 1024px) { 
    font-size: 20px;
    margin: 18px 0;
    width: 800px;
  }
`;


export const ArrowImage = styled.img`
  position: absolute;
  left: -80px; 
  top: 40%;
  rotate: 1deg;
  transform: translateY(-80%); 
  width: 70px;
  height: auto;

  @media screen and (min-width: 768px) {
    top: 25%;
    rotate: -10deg;
    left: -105px; 
    width: 90px;
    transform: translateY(-50%); 
  }

  @media screen and (min-width: 1024px) {
    top: 18%;
    rotate: 0deg;
    left: -145px; 
    width: 100px;
    transform: translateY(-50%); 
  }
`;

export const CTAKontakt = styled(Link) <{ to: string }>`
  display: block;
  border-radius: 50px;
  text-align: center;
  border: 2px solid var(--maindark);
  background-color: transparent;
  color:  var(--maindark);
  text-transform: uppercase;
  text-decoration: none;
  font-family: 'safiromedium';
  font-size: 16px;
  width: 160px;
  padding: 10px 14px;
  margin: 40px 0;

  &:hover {
    background-color:var(--maindark);
    color: #FFF9DE;
  }

  @media screen and (min-width: 768px) {
    margin: 20px 0;
  }
`;

export const SendButton = styled.button`
  border-radius: 50px;
  border: 2px solid var(--maindark);
  background-color: transparent;
  color:  var(--maindark);
  text-transform: uppercase;
  font-family: 'safiromedium';
  font-size: 16px;
  width: 160px;
  height: 45px;
  margin-top: 20px;
  
  &:hover {
    background-color:var(--maindark);
    color: #FFF9DE;
  }
`;



// Process section
export const LpProcessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FFF9DE;
  padding: 5rem 0 7rem 0;
`;

export const LpProcess = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LpProcessBoxes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FFF9DE;
  padding: 20px;
  gap: 2rem;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
  @media screen and (min-width: 1024px) { 
    gap: 6rem;
  }
`;

export const ProcessImg = styled.img`
  width: auto 30%;
  height: 130px;
  margin: 0 auto;
  border-radius: 20px;

  @media screen and (min-width: 768px) {
    height: 150px;
  }
`;

export const LpProcessH4 = styled.h4`
  text-align: center;
  color: var(--maindark);
  font-size: 18px;  
  font-weight: 700;
  margin: 10px 0 8px 0;
  padding: 0;

  @media screen and (min-width: 768px) {
    margin: 18px 0 8px 0;
  }
`;

// Case section
export const LpCases = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 3rem 2rem 7rem 2rem;
`;

export const LpProcessP = styled.p`
  text-align: center;
  color: var(--maindark);
  font-size: 14px;  
  font-weight: 500;
  width: 280px;

  @media screen and (min-width: 768px) {
    font-size: 16px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-wrap: wrap; 
  justify-content: center;
  align-items: center; 
  padding: 0 15px 0 15px;
  gap: 0 10px;

  @media screen and (min-width: 768px) {
    gap: 0 40px;
  }

  @media screen and (min-width: 1024px) {
    gap: 0 70px;
  }
`;


export const LogoWrapper = styled.div`
  height: 40px; 
  display: flex;
  justify-content: center;
  align-items: center; 
  overflow: hidden; 
  margin: 30px 15px 15px 0;

  @media screen and (min-width: 768px) {
    height: 50px; 
  }

  @media screen and (min-width: 1024px) {
    height: 50px;
  }
`;

export const LpCaseLogo = styled.img`
  height: auto; 
  max-height: 100%; 
  width: auto; 
  object-fit: contain;
`;


export const ServicesHeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const TokNojdImg = styled.img`
  height: 65px;
  position: relative;
  top: 30px;
  right: -57px;

  @media screen and (min-width: 425px) {
    top: 30px;
    height: 70px;
    right: -58px;
  }
  @media screen and (min-width: 768px) {
    top: 30px;
    height: 70px;
    right: -59px;
  }

  @media screen and (min-width: 1024px) {
    top: 30px;
    height: 80px;
    right: -63px;
  }
`;


export const CasesH1 = styled.h1`
  text-align: center;
  font-family: 'safirobold';
  color: var(--maindark);
  font-size: 28px;
  margin: 30px 15px 30px;
  padding: 0;
  white-space: nowrap;

  @media screen and (min-width: 768px) {
    font-size: 30px;
  }

  @media screen and (min-width: 1024px) { 
    font-size: 32px;
  }
`;