import React from 'react';
import {
  BaseContainer,
  Bread,
  InfoContainer,
  IngressHeader,
} from "../style/lib";
import { CookieRoll } from '../gsap/rollCookie';
import kaka from '../../assets/kaka-dark.svg';


export const CookiePolicy: React.FC = () => {
  return (
    <BaseContainer $backgroundColor='var(--bg-green)'>

      <InfoContainer>
        <CookieRoll cookieSrc={kaka} backgroundColor='var(--bg-green)' />
        <IngressHeader>Cookies</IngressHeader>
        <Bread>
          På vår webbplats används cookies för att förbättra din användarupplevelse och optimera webbplatsens funktioner.
          Cookies är små, ofarliga textfiler som lagras på din enhet, till exempel din dator eller mobiltelefon.
          De innehåller ingen programkod och kan inte användas för att identifiera dig som person.
          <br /><br />
          Cookies hjälper oss att förstå hur du använder webbplatsen, vilket gör att vi kan förbättra
          funktionaliteten och säkerställa att den fungerar så smidigt som möjligt. Genom att lagra vissa
          inställningar förenklar de även din navigering vid framtida besök.
        </Bread>

      </InfoContainer>
    </BaseContainer>
  );
};