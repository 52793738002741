import React from "react";
import * as LP from "./landingpage-style";
import workcomputer from "../../assets/lp-assets/computer-work.png";
import startup from "../../assets/lp-assets/startup-work.png";
import design from "../../assets/lp-assets/design-agree.png";
import deliver from "../../assets/lp-assets/deliver-time.png";

export const LpProcess: React.FC = () => {
  return (
    <LP.LpProcessContainer>
      <LP.LpH1>Ny hemsida: Såhär går det till</LP.LpH1>


      <LP.LpProcessBoxes>
        <LP.LpProcess>
          <LP.ProcessImg
            src={startup}
            alt="Bild på man som lyssnar, gillar och har kanelbullar som ögon" />

          <LP.LpProcessH4>
            1. Uppstartsmöte
          </LP.LpProcessH4>
          <LP.LpProcessP>
            Vi dricker kaffe, äter bulle och pratar igenom era behov. Ingen detalj är för liten. Er önskan är vår lag.
          </LP.LpProcessP>
        </LP.LpProcess>


        <LP.LpProcess>
          <LP.ProcessImg
            src={design}
            alt="Händer som möts för en god idé" />

          <LP.LpProcessH4>
            2. Designförslag
          </LP.LpProcessH4>
          <LP.LpProcessP>
            Med er visdom och vår expertis tar vi fram en design vi tror ni kommer älska. När vi får godkänt
            börjar vi koda.
          </LP.LpProcessP>
        </LP.LpProcess>

      </LP.LpProcessBoxes>


      <LP.LpProcessBoxes>
        <LP.LpProcess>
          <LP.ProcessImg
            src={workcomputer}
            alt="Dator som arbetar" />
          <LP.LpProcessH4>
            3. Bygger hemsida
          </LP.LpProcessH4>
          <LP.LpProcessP>
            Vi fortsätter dricka kaffe, men skippar bullen
            och kodar järnet i två
            till tre veckor.
          </LP.LpProcessP>
        </LP.LpProcess>

        <LP.LpProcess>
          <LP.ProcessImg
            src={deliver}
            alt="Person med levererar paket" />
          <LP.LpProcessH4>
            4. Leverans
          </LP.LpProcessH4>
          <LP.LpProcessP>
            Kaffet har tagit slut och det är dags att visa oss värdiga.
            Njut av er nya webbplats!
            (Vi tar hand om resten).
          </LP.LpProcessP>
        </LP.LpProcess>

      </LP.LpProcessBoxes>
    </LP.LpProcessContainer>
  );
}