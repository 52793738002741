import React, { useState } from "react";
import * as Lib from "../style/lib";
import { AllContainer, ProfileContainer, ProfileImg, ProfileTitle } from "../style/aboutUs-style";
import { Modal } from "../utils/modal";
import { Information, Employees } from "../utils/employees";
import { BubbleUp } from "../utils/bubble";
import { FadeInOnScroll } from "../gsap/scrollTrigg";
import { LineGradientSection } from "../gsap/transition";

export const AboutUs: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<Information>({} as Information);

  const openModal = (information: Information) => {
    setModalContent(information);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <Lib.BaseContainer
        $backgroundColor='var(--mainlight)'
        $padding={['0.4rem 0 4rem', '1rem 0 5rem', '2rem 0 7rem']}
        id="aboutus">
        <Lib.TextContainer>
          <Lib.InfoContainer $alignItems="center">
            <FadeInOnScroll>
              <Lib.IngressContainer>
                <Lib.IngressHeader>
                  Vilka är vi?
                </Lib.IngressHeader>
              </Lib.IngressContainer>

              <Lib.IngreassParagraph>
                Vi är ett gäng nördiga och nyfikna experter.
                Vårt mål är att hjälpa våra kunder att genomföra värdehöjande förbättringar.
                Detta gör vi genom att ta fram en arkitektur som är lätt att både jobba med och förstå.
                Vi brukar säga att vi vill göra oss umbärliga.
              </Lib.IngreassParagraph>
            </FadeInOnScroll>

            <AllContainer>
              {Employees.map((employee, index) => (
                <ProfileContainer key={employee.name} onClick={() => openModal(employee.information)}>
                  <ProfileImg
                    src={employee.image}
                    alt={employee.name}
                    $margin={index === 2 ? '18px 0 0' : '0'}
                  />
                  <Lib.LinkLine
                    className="link-line"
                    $fontSize="16px"
                    $fontSizeTablet="17px"
                    $fontSizeDesktop="18px"
                  >
                    {employee.name}
                  </Lib.LinkLine>
                  <ProfileTitle>
                    {employee.title}
                  </ProfileTitle>
                </ProfileContainer>
              ))}
              <Modal isOpen={isModalOpen} onClose={closeModal} information={modalContent} />

              <ProfileContainer >
                <BubbleUp />
              </ProfileContainer>

            </AllContainer>

          </Lib.InfoContainer>
        </Lib.TextContainer>
      </Lib.BaseContainer>
      <LineGradientSection
        containerBackgroundColor="var(--bg-pink)"
        lineStartColor="var(--mainlight)"
        lineRightColor="var(--mainlight)"
        lineLeftColor="var(--mainlight)"
      />
    </>
  )
};