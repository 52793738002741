import styled from "styled-components";

interface ModalOverlayProps {
  $isOpen: boolean;
}

export const ModalOverlay = styled.div<ModalOverlayProps>`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.631);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: ${(props) => props.$isOpen ? "hidden" : "scroll"}; 
`

export const ModalThing = styled.div`
  background: var(--maindark);
  color: var(--mainlight);
  padding: 40px;
  border-radius: 5px;
  position: relative;
  min-width: 300px;
  max-width: 790px;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  margin: 0;

  /* Tablet  */
  @media (min-width: 767px) {
    margin: 20px;
    height: 580px;
    padding: 50px 70px;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    font-size: 26px;
    line-height: 30px;
  }
`
export const ModalClose = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  font-size: 20px;
  cursor: pointer;
`
export const NameModal = styled.h3`
color: var(--text);
font-family: 'safiromedium';
font-weight: 600;
font-size: 22px;
text-align: left;
text-transform: uppercase;
line-height: 20px;
max-width: 100%;
margin-top: 20%;


  /* Tablet and beyond  */
  @media (min-width: 768px) {
    margin-top: 10%;
  }
`

export const TitleModal = styled.h3`
color: var(--text);
font-family: 'Inter', sans-serif;
font-weight: 300;
font-size: 18px;
text-align: left;
line-height: 28px;
max-width: 100%;
`

export const InfoBread = styled.p`
 color: var(--text);
 font-family: 'Inter', sans-serif;
 font-weight: 300;
 font-size: 16px;
 margin: 30px 0;
 line-height: 25px;
 max-width: 100%;

   /* Tablet and beyond  */
   @media (min-width: 768px) {
    max-width: 95%;
    font-size: 18px;
  }
`

export const CrossImg = styled.img`
  width: 18px;
  margin: 18px ;
  align-self: flex-end;
`

export const LogoImg = styled.img`
  position: absolute; 
  bottom: 40px; 
  right: 30px;
  width: 70px;
  margin: 0;
  align-self: flex-end;

   /* Tablet and beyond  */
   @media (min-width: 768px) {
    bottom: 30px; 
  }
`