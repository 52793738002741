import React, { useEffect } from 'react';
import styled from 'styled-components';




const TackContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--maindark);
  justify-content: center;
  padding: 6rem 0 7rem 0;
  align-items: center;
  height: 70vh;

  @media (min-width: 768px) {
    padding: 8rem 0 9rem 0; 
  }

  @media (min-width: 1024px) {
    padding: 10rem 0 11rem 0; // Desktop padding
  }
`;

const TackHeader = styled.h1`
 color: var(--text);
 font-family: 'safirobold';
 font-size: 38px;
 text-align: left;
 text-transform: uppercase;
 line-height: 38px;
 margin-bottom: 18px;


 /* Tablet  */
 @media (min-width: 768px) {
  font-size: 45px;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    font-size: 55px;
  }
`;

export const TackParagraph = styled.p`
 color: var(--text);
 font-family: 'safiromedium';
 font-weight: normal;
 font-size: 18px;
 text-align: left;
 margin: 30px 0 -18px 0;
 text-transform: uppercase;
`;

export const Tack: React.FC = () => {

  // Dynamically add a noindex meta tag when this component is mounted
  useEffect(() => {
    const metaTag = document.createElement('meta');
    metaTag.name = 'robots';
    metaTag.content = 'noindex';
    document.head.appendChild(metaTag);

    // Clean up: remove the noindex meta tag when the component is unmounted
    return () => {
      document.head.removeChild(metaTag);
    };
  }, []);

  return (
    <TackContainer>

      <TackHeader>Tack!</TackHeader>

      <TackParagraph>
        Vi hör av oss så snart vi kan!
      </TackParagraph>


    </TackContainer>
  );
};
