import React from "react";
import { LpIntro } from "./lp-intro";
import { LpProcess } from "./lp-process";
import { LpFAQ } from "./lp-faq";
import { LpContact } from "./lp-contact";
import { LpCase } from "./lp-case";
import { LpServices } from "./lp-services";


export const LandingpageWebb = () => {

  return (
    <>
      <LpIntro />
      <LpProcess />
      <LpCase />
      <LpContact />
      <LpFAQ />
      <LpServices />
    </>
  );
}