import React from "react";
import * as LP from "./landingpage-style";
import * as LPM from "./lp-services-style";
import ux from "../../assets/lp-assets/icons/uxdesign.svg";
import webdev from "../../assets/lp-assets/icons/webbutv.svg";
import hosting from "../../assets/lp-assets/icons/hosting.svg";


export const LpServices: React.FC = () => {
  return (
    <LPM.LpProcessContainer>

      <LP.LpH1 $color="var(--mainlight)">
        Våra tjänster
      </LP.LpH1>

      <LPM.ServiceRowContainer>

        <LPM.LpMoreCard>
          <LPM.LpSIcons src={webdev} alt="Webbutveckling & SEO optimering" />
          <LPM.LpH3>Webb & SEO</LPM.LpH3>
          <LPM.LpServieceBread>
            Vi skapar SEO-anpassade webbplatser som konverterar besökare till kunder.
          </LPM.LpServieceBread>
          <LPM.ReadMoreButton to="/webbutveckling" />
        </LPM.LpMoreCard>

        <LPM.LpMoreCard>
          <LPM.LpSIcons src={ux} alt="Design & UX" />
          <LPM.LpH3>Design & UX</LPM.LpH3>
          <LPM.LpServieceBread>
            Vi designar användarvänliga och snygga gränssnitt med det lilla extra.
          </LPM.LpServieceBread>
          <LPM.ReadMoreButton to="/webbutveckling" />
        </LPM.LpMoreCard>

        <LPM.LpMoreCard>
          <LPM.LpSIcons src={hosting} alt="Hosting och förvaltning" />
          <LPM.LpH3>Hosting & förvaltning</LPM.LpH3>
          <LPM.LpServieceBread>
            Vi erbjuder snabb och säker hosting samt förvaltning av din webbplats.
          </LPM.LpServieceBread>

          <LPM.ReadMoreButton to="/webbutveckling" />

        </LPM.LpMoreCard>

      </LPM.ServiceRowContainer>

    </LPM.LpProcessContainer>
  );
}