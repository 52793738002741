import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MainLayout } from '../mainLayout';
import { SingleLayot } from '../singleLayout';
import { IntroPage } from '../pages/intro';
import { OurServices } from '../pages/whatIntro';
import { AboutUs } from '../pages/aboutUs';
import { Contact } from '../pages/contact';
import { WorkWithUs } from '../pages/workWithUs';
import { PrivacyPolicy } from '../utils/privacy';
import { NotFound } from '../pages/notFound';
import { Webbutveckling } from '../pages/webdevelopment';
import { CookieComponent } from '../utils/cookies';
import { ScrollToTop } from './scrollTo';
import { LandingpageWebb } from '../landingpage/landingPage';
import { CookiePolicy } from './cookie-info';
import { Tack } from '../pages/tack';
import { Konsulter } from '../pages/consults';


const AppRoutes: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <MainLayout>
              <IntroPage />
              <OurServices />
              <AboutUs />
              <Contact />
            </MainLayout>
          }
        />

        <Route
          path="/webbutveckling"
          element={
            <MainLayout>
              <Webbutveckling />
            </MainLayout>
          }
        />

        <Route
          path="/konsulter"
          element={
            <MainLayout>
              <Konsulter />
            </MainLayout>
          }
        />

        <Route
          path="/workwithus"
          element={
            <MainLayout>
              <WorkWithUs />
            </MainLayout>
          }
        />
        <Route
          path="/privacy"
          element={
            <MainLayout>
              <PrivacyPolicy />
            </MainLayout>
          }
        />
        <Route
          path="/cookies"
          element={
            <MainLayout>
              <CookiePolicy />
            </MainLayout>
          }
        />
        <Route
          path="/webbutveckling-hemsidor"
          element={
            <MainLayout isLandingPage>
              <LandingpageWebb />
            </MainLayout>
          }
        />
        <Route
          path="/tack"
          element={
            <MainLayout>
              <Tack />
            </MainLayout>
          }
        />
        <Route
          path="*"
          element={
            <SingleLayot>
              <NotFound />
            </SingleLayot>
          }
        />
      </Routes>
      <CookieComponent />
    </Router>
  );
};

export default AppRoutes;
