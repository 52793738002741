import React from "react";
import * as LP from "./landingpage-style";
import coop from "../../assets/lp-assets/coop-logo.png";
import ving from "../../assets/lp-assets/ving-logo.png";
import skandia from "../../assets/lp-assets/skandia-logo.png";
import manpower from "../../assets/lp-assets/manpower-logo.png";
import vwfs from "../../assets/lp-assets/vwfs-logo.png";
import avtalat from "../../assets/lp-assets/avtalat-logo.png";
import hn from "../../assets/lp-assets/hnlp-logo.png";
import toknojd from "../../assets/lp-assets/lp-toknojd.svg";


export const LpCase: React.FC = () => {
  return (
    <LP.LpCases>

      <LP.ServicesHeadContainer>
        <LP.TokNojdImg src={toknojd} alt="Toknöjd bild" aria-hidden="true" />
        <LP.CasesH1>Några av våra kunder:</LP.CasesH1>
      </LP.ServicesHeadContainer>
      <LP.LogoContainer>
        <LP.LogoWrapper>

          <LP.LpCaseLogo src={coop} alt="Coop logotyp" />

        </LP.LogoWrapper>
        <LP.LogoWrapper>

          <LP.LpCaseLogo src={ving} alt="Ving logotyp" />

        </LP.LogoWrapper>
        <LP.LogoWrapper>

          <LP.LpCaseLogo src={skandia} alt="Skandia logotyp" />

        </LP.LogoWrapper>
        <LP.LogoWrapper>

          <LP.LpCaseLogo src={manpower} alt="Manpower logotyp" />

        </LP.LogoWrapper>
        <LP.LogoWrapper>

          <LP.LpCaseLogo src={vwfs} alt="Volkswagen Financial Services logotyp" />

        </LP.LogoWrapper>
        <LP.LogoWrapper>

          <LP.LpCaseLogo src={hn} alt="Haninge Naprapat AB logotyp" />

        </LP.LogoWrapper>
        <LP.LogoWrapper>

          <LP.LpCaseLogo src={avtalat} alt="Avtalat logotyp" />

        </LP.LogoWrapper>

      </LP.LogoContainer>

    </LP.LpCases>
  );
}