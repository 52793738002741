import styled from 'styled-components';
import React from 'react';
import { Link } from 'react-router-dom';

export const LpProcessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--maindark );
  padding: 5rem 0 7rem 0;
`;
export const ServiceRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--maindark);

  @media screen and (min-width: 1024px) { 
    flex-direction: row;
  }
`;
export const LpMoreCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 18px;
  margin: 20px 5px;
  height: 300px;
  max-width: 370px;
  background-color: #fff;
  box-sizing: border-box;

  @media screen and (min-width: 768px) {
    height: 350px;
    max-width: 490px;
    padding: 30px 18px;
  }

  @media screen and (min-width: 1024px) { 
    font-size: 32px;
    height: 400px;
    margin: 20px;
    padding: 10px 15px;
  }
`;
export const LpSIcons = styled.img`
  width: 60px;
  margin: 0 0 8px 0;

   /* Tablet  */
 @media (min-width: 768px) {
  width: 70px;
  margin: 0 0 15px 0;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    width: 70px;
  }
`;
export const LpH3 = styled.h3`
  font-family: 'safirobold';
  color: var(--maindark);
  font-size: 22px;

  @media screen and (min-width: 768px) {
    font-size: 24px;
  }

  @media screen and (min-width: 1024px) { 
    font-size: 26px;
  }
`;
export const LpServieceBread = styled.p`
  color: var(--maindark);
  font-size: 14px;
  margin: 18px 0;
  @media screen and (min-width: 768px) {
    margin: 18px 0 40px 0;
    font-size: 16px;
  }
  @media screen and (min-width: 1024px) { 
    font-size: 18px;
    margin: 18px 0 40px 0;
  }
`;

interface ReadMoreButtonProps {
  to?: string;
}

// Styled component for the button
const ReadMoreLink = styled(Link) <ReadMoreButtonProps>`
  text-align: center;
  border-radius: 50px;
  border: 2px solid var(--maindark);
  background-color: transparent;
  color: var(--maindark);
  text-transform: uppercase;
  text-decoration: none;
  font-family: 'safiromedium';
  font-size: 16px;
  width: 120px;
  padding: 10px 15px;

  &:hover {
    background-color: var(--maindark);
    color: var(--text);
  }

  &:active {
    background-color: var(--bg-pink);
    border: 3px solid var(--bg-pink);
    color: var(--text);
  }

  @media (min-width: 1024px) {
    font-size: 16px;
    margin: 0;
    padding: 11px 22px;
  }
`;

// Component definition
export const ReadMoreButton: React.FC<ReadMoreButtonProps> = ({ to = '/' }) => {
  return (
    <ReadMoreLink to={to}>
      Läs mer
    </ReadMoreLink>
  );
};