import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const LpContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffa0ff59;
  padding: 5rem 0 7rem 0;
`;

export const LpFormInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  min-width: 300px;

  @media (min-width: 768px) {
    min-width: 550px;
  }

  @media (min-width: 1024px) {
    min-width: 530px;
  }
`;



export const LpForm = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0;
`;

export const LpLabel = styled.label`
  font-family: 'safirobold';
  font-weight: 200;
  margin-bottom: 8px;
  font-size: 16px;

  @media (min-width: 768px) {
    font-size: 18px;
  }

`;

export const LpInputContacts = styled.input`
  padding: 8px;
  border: none;
  border-bottom: 1px solid var(--darktext);
  font-size: 16px;
  background-color: #FFDDFA; 
`;

export const LpDetailsInput = styled.input`
position: absolute;
left: -10000px; 
top: auto;
width: 1px;     
height: 1px;
opacity: 0;      
z-index: -1;    
overflow: hidden;
`;


export const LpErrorMsg = styled.p`
  color: var(--darktext);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  margin: 8px 0;
`;


//Chechboxing
export const LpCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
`;

export const LpCheckboxInput = styled.input`
  appearance: none; /* Remove default styling */
  -webkit-appearance: none; /* Safari */
  -moz-appearance: none; /* Firefox */
  width: 20px;
  height: 20px;
  border: 2px solid #050024ac; 
  border-radius: 0px;
  background-color: #fff; 
  position: relative;
  outline: none;
  margin-right: 8px;

  &:checked::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px; 
    width: calc(100% - 4px); 
    height: calc(100% - 4px);
    background-color: var(--darktext); 
    border-radius: 0; 
  }
`;

export const LpCheckboxLabel = styled.label`
    font-family: 'Inter', sans-serif;
    font-weight: 200;
    font-weight: 500;
    font-size: 14px;
`;


export const VillkorLink = styled(Link) <{ to: string }>`
  position: relative;
  color: var(--darktext);
  font-size: 14px; 
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
  margin: 5px 0;

  &:hover {
    color: var(--darktext);
  }

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    height: 4px;
    bottom: -2px;
    left: 0;
    background-color: var(--bg-pink);
    transform-origin: left;
    transition: width 0.5s ease;
  }

  &:hover::before {
    width: 100%;
  }
`;


export const SendMsgText = styled.div`
  font-family: 'safirobold';
  font-size: 16px;
  margin: 25px 0;
  text-align: center;
  color: var(--darktext);
  background-color: #40becc87;
  padding: 15px 10px;
`;

export const ErrorMsgText = styled.div`
  font-family: 'safirobold';
  font-size: 16px;
  margin: 20px 0;
  text-align: center;
  color: var(--darktext);
  background-color: #ffa0ff59;
  padding: 10px 15px;
`;