import React from "react";
import * as LP from "./landingpage-style";
import arrow from "../../assets/lp-assets/lp-arrow.svg";


export const LpIntro: React.FC = () => {
  return (
    <LP.Lpintro>
      <LP.LpH1>Digitala <LP.HighlightSpan $color="#E036E0">lösningar</LP.HighlightSpan> som gör skillnad
        anpassade för era <LP.HighlightSpan $color="#40BFCC">unika</LP.HighlightSpan> behov</LP.LpH1>
      <LP.LpBread>
        Vi tar hand om varje steg – från första idé till lansering och vidare.
        Vi skapar webbplatser och digitala lösningar anpassade efter era specifika behov,
        med fokus på säkerhet, snabbhet och SEO-optimering som hjälper er att växa.
      </LP.LpBread>
      <div style={{ position: 'relative' }}>
        <LP.ArrowImage src={arrow} alt="Arrow" />

        <LP.CTAKontakt to="#contactform">
          Kontakta oss
        </LP.CTAKontakt>
      </div>
    </LP.Lpintro>
  );
}