import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  BaseContainer,
  Bread,
  InfoContainer
} from "../style/lib";
import * as Style from "./../style/contact-style";

const PrivacyHeader3 = styled.h3`
 color: var(--darktext);
 font-family: 'safirobold';
 font-size: 28px;
 text-align: left;
 text-transform: uppercase;
 line-height: 38px;
 margin-bottom: 18px;


 /* Tablet  */
 @media (min-width: 768px) {
  font-size: 45px;
  margin-bottom: 0;
  line-height: 50px;
  width: 98%;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    font-size: 55px;
    line-height: 70px;
  }
`;

const PrivacyHeader5 = styled.h5`
 color: var(--darktext);
 font-family: 'safiromedium';
 font-weight: normal;
 font-size: 18px;
 text-align: left;
 margin: 30px 0 -18px 0;
 text-transform: uppercase;

 @media (min-width: 768px) {
  font-size: 20px;
  }

 @media (min-width: 1024px) {
    font-size: 24px;
  }
`;

const PageLink = styled(Link) <{ to: string }>`
  position: relative;
  color: var(--darktext);
  font-size: 16px; 
  font-weight: 300;
  text-decoration: none;
  display: inline-block;
  margin: 5px 0;

  &:hover {
    color: var(--darktext);
  }

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    height: 4px;
    bottom: -2px;
    left: 0;
    background-color: var(--bg-pink);
    transform-origin: left;
    transition: width 0.5s ease;
  }

  &:hover::before {
    width: 100%;
  }

   /* Tablet */
   @media (min-width: 768px) {
    font-size: 20px;
  }

  @media (min-width: 1024px) {
    font-size: 22px;
  }
`;

export const PrivacyPolicy: React.FC = () => {
  return (
    <BaseContainer $backgroundColor='var(--bg-green)'>

      <InfoContainer>
        <PrivacyHeader3>Integritetspolicy</PrivacyHeader3>
        <Bread>
          Andtech respekterar och skyddar våra kunders och besökares personuppgifter.
          Denna policy beskriver hur vi hanterar personlig information som samlas in på <PageLink to="/">www.andtech.se</PageLink>.
          <br /><br />
          Genom att använda vår webbplats samtycker du till behandlingen av dina personuppgifter enligt denna
          integritetspolicy.
        </Bread>

        <PrivacyHeader5>Vad är personuppgifter?</PrivacyHeader5>
        <Bread>
          Personuppgifter är information som kan kopplas till en identifierbar fysisk person, antingen direkt
          eller indirekt, såsom namn, personnummer, platsdata eller IP-adress.
        </Bread>


        <PrivacyHeader5> Vilka personuppgifter samlar vi in?</PrivacyHeader5>
        <Bread>
          Information du lämnar till oss:
          <br />
          Data som du aktivt förser oss med genom att fylla i olika formulär på
          vår webbplats. <br /><br />
          Information vi samlar in automatiskt:  <br />
          Uppgifter om hur du använder vår webbplats, inklusive din IP-adress,
          webbläsarinställningar och operativsystemets interaktion med våra sidor.
          Denna information behandlas baserat på en intresseavvägning och används för att förbättra webbplatsens
          funktion och innehåll.
        </Bread>

        <PrivacyHeader5>Varför behandlar vi dina personuppgifter?</PrivacyHeader5>
        <Bread>
          Den information vi samlar in används för följande ändamål:
          <br />
          – För att erbjuda personlig service  <br />
          – Dina uppgifter hjälper oss att bättre förstå och tillgodose dina individuella behov.  <br />
          – Om du har aktiverat cookies kan vi använda denna information för att ge dig en anpassad upplevelse.  <br />
          – För att förbättra vår webbplats.  <br />
          – Vi arbetar kontinuerligt med att utveckla och optimera vår webbplats baserat på dina synpunkter och
          användningsmönster.  <br />
          – För att förbättra vår kommunikation.  <br />
          – Din e-postadress kan användas för att skicka information om exempelvis event eller nya tjänster.  <br />
          – Du kan när som helst avbryta din prenumeration genom att följa instruktionerna i våra e-postutskick.
        </Bread>

        <PrivacyHeader5>Vem delar vi dina personuppgifter med? </PrivacyHeader5>
        <Bread>
          Vi säljer inte, byter eller på annat sätt överför dina personuppgifter till tredje part.
        </Bread>

        <PrivacyHeader5>Behandling av personuppgifter vid kontakt och intresseanmälan</PrivacyHeader5>
        <Bread>
          När du skickar en intresseanmälan eller fyller i kontaktformulär på vår webbplats, efterfrågar vi
          vissa personuppgifter för att kunna återkoppla till dig angående ditt ärende.
          De uppgifter vi behandlar är de som du själv har lämnat till oss via formuläret.
        </Bread>

        <PrivacyHeader5>Behandling av personuppgifter vid rekrytering</PrivacyHeader5>
        <Bread>
          I samband med rekrytering lagrar vi personuppgifter och relevant information som anses nödvändig för
          att underlätta en effektiv rekryteringsprocess. De uppgifter vi behandlar inhämtas från offentliga
          källor samt information du själv har tillhandahållit. Vi behåller dina personuppgifter så länge vi
          anser att du kan vara en potentiell kandidat för våra tjänster; uppgifterna granskas årligen för
          att säkerställa att de är aktuella.
        </Bread>

        <PrivacyHeader5>Cookies</PrivacyHeader5>
        <Bread>
          För information om hur vi använder cookies, vänligen besök vår   <PageLink to="/cookies">cookie-sida</PageLink>.
        </Bread>

        <PrivacyHeader5>Hur länge sparar vi dina personuppgifter?
        </PrivacyHeader5>
        <Bread>
          Vi behåller dina personuppgifter endast så länge det är nödvändigt för att uppfylla våra
          avtalsförpliktelser och i enlighet med lagstadgade lagringstider.
          <br /><br />
          Om vi behandlar dina personuppgifter för andra ändamål än våra avtalsförpliktelser, till exempel när du har
          samtyckt till att motta nyhetsbrev, behandlar vi uppgifterna endast så länge det är
          nödvändigt och/eller krävs enligt lag.
        </Bread>

        <PrivacyHeader5> Ändringar i denna integritetspolicy
        </PrivacyHeader5>
        <Bread>
          Andtech förbehåller sig rätten att när som helst uppdatera eller ändra denna policy.
          Vi rekommenderar att du regelbundet besöker denna sida för att ta del av den senaste versionen.
          <br /><br />
          Kontakta oss om du har frågor angående denna integritetspolicy, vår behandling av personuppgifter
          eller vill utöva dina rättigheter, vänligen kontakta oss på e-post  <Style.LinkForWork
            href="mailto: info@andtech.se"
          >
            info@andtech.se
          </Style.LinkForWork>
        </Bread>

      </InfoContainer>
    </BaseContainer>
  );
};
