import React from "react";
import * as Serv from "../style/whatWeDo-style";
import * as Lib from "../style/lib";
import system from "../../assets/lp-assets/icons/systemutv.svg";
import devops from "../../assets/lp-assets/icons/devops.svg";
import arkitektur from "../../assets/lp-assets/icons/arkitektur.svg";
import { LpCase } from "../landingpage/lp-case";
export const Konsulter: React.FC = () => {

  return (
    <>
      <Lib.BaseContainer
        $backgroundColor='var(--bg-green)'
        $padding={['1rem 0 4rem', '2rem 0 5rem', '3rem 0 7rem']}>
        <Lib.TextContainer>
          <Lib.InfoContainer>
            <Lib.IngressContainer>
              <Lib.IngressHeader>
                Konsulter
              </Lib.IngressHeader>
            </Lib.IngressContainer>
            <Lib.Bread>
              Vi har några av de mest engagerade IT-konsulterna, vilket gör oss till en pålitlig partner för alla dina
              tekniska utmaningar. Våra konsulter är inte bara experter på teknik; de skapar också en djup förståelse
              för verksamheten och dess behov.
              <br /><br />
              Med ödmjukhet, nyfikenhet och spetskompetens bidrar våra konsulter effektivt till era projekt och team.
              Oavsett om det gäller att leda komplexa utvecklingsprojekt eller förbättra befintliga lösningar,
              säkerställer de att era mål uppnås smidigt och framgångsrikt.
            </Lib.Bread>
            <Serv.ServiceParagraph>Rätt match</Serv.ServiceParagraph>
            <Lib.Bread>
              Genom att nischa oss och ha nära kundrelationer, har vi full koll på våra profiler och kan därför snabbt matcha rätt profil för er.
            </Lib.Bread>

            <Lib.Bread>
              Vi värdesätter långsiktiga relationer där vi växer och utvecklas tillsammans.
            </Lib.Bread>

            <Serv.WhatWeDoContainer>
              <Serv.DevLogos src={arkitektur} alt="Arkitektur" />
              <Serv.TypeHeader>
                Arkitektur
              </Serv.TypeHeader>
            </Serv.WhatWeDoContainer>
            <Lib.Bread>
              Med vår mångåriga erfarenhet från olika verksamheter och branscher hjälper våra arkitekter er att
              bygga en hållbar teknisk plan för framtiden.
              <br /> <br />
              Vi designar lösningar som inte bara möter dagens behov utan också kan anpassas för framtida utveckling.
              Oavsett vilka utvecklare som arbetar med systemet om fem år, ser vi till att det är lätt att underhålla och vidareutveckla.
            </Lib.Bread>

            <Serv.WhatWeDoContainer>
              <Serv.DevLogos src={system} alt="Systemutveckling" />
              <Serv.TypeHeader>
                Systemutveckling
              </Serv.TypeHeader>
            </Serv.WhatWeDoContainer>
            <Lib.Bread>
              Våra utvecklare brinner för enkel, effektiv kod. Med expertis inom .NET och React skapar vi
              lösningar som är optimerade för era specifika behov, utan onödig komplexitet. Genom lagarbete
              och ett stort fokus på er verksamhet ser vi till att varje rad kod är meningsfull och hållbar<br />
              <br />

              <Serv.ServiceParagraph>Våra expertområden:</Serv.ServiceParagraph>
              – Frontend utveckling (React)<br />
              – Backend och Api-lösningar (.net, Node.js, Next.js)<br />
              – Systemintegration (Azure Functions, BizTalk)<br />
              – Databasutveckling (Sql-Server)<br />
            </Lib.Bread>


            <Serv.WhatWeDoContainer>
              <Serv.TypeHeader>
                Azure effektivisering
              </Serv.TypeHeader>
            </Serv.WhatWeDoContainer>

            <Lib.Bread>
              Är er Azure-plattform dyrare än förväntat?
              <br /><br />
              Ofta beror det på en organiskt växande plattform där applikationer, databaser och andra resurser
              ligger på dyrare betalningsplaner än nödvändigt för att hantera prestandatoppar.
              Våra Azure-experter vet exakt hur man optimerar applikationer över rätt betalningsplaner och utnyttjar
              skalbarhet för att sänka kostnaderna rejält.
              <br /><br />
              Vi bjuder på en enkel och kostnadsfri analys som ger er en klar bild av hur mycket ni kan spara med
              vår hjälp.
              <br /><br />

              <Serv.ServiceParagraph>Vi är specialister inom:</Serv.ServiceParagraph>
              – App Services<br />
              – Function Apps<br />
              – Entra ID<br />
              – API Management Services <br />
              – SQL Server-databaser <br />
              – Storage Accounts <br />
              – Service Bus <br />
              – Key Vault <br />
              <br />
              Oavsett var ni står idag kan vi lyfta er DevOps-strategi till nästa nivå med lösningar
              som stöder både er affärsverksamhet och IT-avdelnings långsiktiga vision.
            </Lib.Bread>

            <Serv.WhatWeDoContainer>
              <Serv.DevLogos src={devops} alt="DevOps optimering" />
              <Serv.TypeHeader>
                DevOps
              </Serv.TypeHeader>
            </Serv.WhatWeDoContainer>

            <Lib.Bread>
              Att snabbt leverera testade mjukvaruversioner är en konkurrensfördel,
              men det kräver en robust och säker process.
              <br /><br />
              Med Azure DevOps kan ni uppnå båda. På Andtech har vi över 10 års erfarenhet av att implementera
              skalbara och hållbara DevOps-lösningar som matchar era mål och IT-strategi.
              <br /><br />
              Vi förespråkar shift left—att automatisera tester och säkerhetskontroller tidigt i processen.
              <br /><br />
              Vi hjälper er att skapa optimerade och säkra DevOps-pipelines som sparar tid, minskar risker
              och stärker samarbetet mellan utveckling och drift.
            </Lib.Bread>

            <Serv.ServiceParagraph>Våra expertområden: </Serv.ServiceParagraph>
            <Lib.Bread>
              – Multistage-Pipelines (YAML som täcker bygg, test och release)      <br />
              – Infrastruktur som kod (Bicep, ARM)      <br />
              – Shift-left, inför test och kontroll av säkerhet tidigt i processen      <br />
              – Build agents för Linux och Windows      <br />
              – Automatisering genom Powershell och Bash-skript      <br />
              – Versionshantering med Git och TFVC      <br />
              <br />
              Oavsett var ni står idag kan vi lyfta er DevOps-strategi till nästa nivå med lösningar
              som stöder både er affärsverksamhet och IT-avdelnings långsiktiga vision.
            </Lib.Bread>



          </Lib.InfoContainer>
        </Lib.TextContainer>

      </Lib.BaseContainer >
      <Lib.BaseContainer
        $backgroundColor="#fff"
        $padding={['0rem 1rem', '0.5rem', '1rem 2rem']}>
        <LpCase />
      </Lib.BaseContainer>
    </>
  )
}