import React from "react";
import * as Lib from "../style/lib";
import * as WS from "../style/whatWeDo-style";
import { FadeInOnScroll } from "../gsap/scrollTrigg";
import webdev from "../../assets/lp-assets/icons/webbutv.svg";
import konsult from "../../assets/lp-assets/icons/konsults.svg";


export const OurServices: React.FC = () => {
  return (

    <Lib.BaseContainer
      $backgroundColor='var(--bg-green)'
      $padding={['4rem 0 4rem', '6rem 0 5rem', '5rem 0 7rem']}
      id="whatwedo">
      <Lib.TextContainer>
        <FadeInOnScroll>

          <Lib.InfoContainer>
            <Lib.IngressHeader >
              Din digitala framtid<br />
              börjar här.
            </Lib.IngressHeader>

            <Lib.IngreassParagraph>
              AndTech grundades med övertygelsen om att det är teknik som driver bolag framåt.
              Vi bygger på långvariga samarbeten och strävar efter att skapa något unikt tillsammans.
              Genom att kombinera dina affärsmål med användarnas behov, hittar vi lösningar som både
              håller över tid och skapar verkligt värde.
            </Lib.IngreassParagraph>
          </Lib.InfoContainer>
        </FadeInOnScroll>
      </Lib.TextContainer>


      <WS.ServicesContainer>
        <WS.ServiceRowContainer>

          <WS.ServicesCard>
            <WS.ServiceIcons src={webdev} alt="Webbutveckling & SEO optimering" />

            <WS.ServiceHead3>Webbutveckling</WS.ServiceHead3>

            <WS.ServieceBread>
              Vi skapar skräddarsydda, användarvänliga webblösningar med fokus på SEO – optimerade för alla enheter.
              Från lansering till underhåll ser vi till att din online-närvaro alltid presterar.
            </WS.ServieceBread>
            <WS.ReadMoreButton to="/webbutveckling" />
          </WS.ServicesCard>

          <WS.ServicesCard>
            <WS.ServiceIcons
              src={konsult}
              alt="Konsulttjänster" />

            <WS.ServiceHead3>
              Konsulter
            </WS.ServiceHead3>

            <WS.ServieceBread>
              Engagerade konsulter för dina utmaningar.<br />
              Vi skräddarsyr lösningar som matchar dina behov och ser till att rätt kompetens finns med genom hela resan.
            </WS.ServieceBread>

            <WS.ReadMoreButton
              to="/konsulter" />

          </WS.ServicesCard>

        </WS.ServiceRowContainer>

      </WS.ServicesContainer >




    </Lib.BaseContainer>


  );
}